var handlerInfoFactory = require("./handler-infos");

var utils = require("./utils");
var callHook = utils.callHook;

function RouterState(router) {
	this.router = router;
	this.differentToPrevious = false;
	this.handlerInfos = [];
	this.params = {};
	this.queryParams = {};
}

RouterState.prototype.add = function (handlerInfo) {
	this.params[handlerInfo.name] = handlerInfo.params || {};
	this.handlerInfos.push(handlerInfo);
	return handlerInfo;
};

RouterState.prototype.resolve = function (shouldContinue, payload) {
	var currentState = this;
	var wasAborted = false;

	payload = payload || {};
	payload.resolveIndex = 0;

	function innerShouldContinue() {
		return Promise.resolve(shouldContinue())["catch"](function (reason) {
			// We distinguish between errors that occurred
			// during resolution (e.g. beforeModel/model/afterModel),
			// and aborts due to a rejecting promise from shouldContinue().
			wasAborted = true;
			return Promise.reject(reason);
		});
	}

	function handleError(error) {
		// This is the only possible
		// reject value of TransitionState#resolve
		var handlerInfos = currentState.handlerInfos;
		var errorHandlerIndex = payload.resolveIndex >= handlerInfos.length ?
				handlerInfos.length - 1 : payload.resolveIndex;

		return Promise.reject({
			error: error,
			handlerWithError: currentState.handlerInfos[errorHandlerIndex].handler,
			wasAborted: wasAborted,
			state: currentState
		});
	}

	function proceed(resolvedHandlerInfo) {
		var wasAlreadyResolved = currentState.handlerInfos[payload.resolveIndex].isResolved;

		// Swap the previously unresolved handlerInfo with
		// the resolved handlerInfo
		currentState.handlerInfos[payload.resolveIndex++] = resolvedHandlerInfo;

		if (!wasAlreadyResolved) {
			// Call the redirect hook. The reason we call it here
			// vs. afterModel is so that redirects into child
			// routes don't re-run the model hooks for this
			// already-resolved route.
			var handler = resolvedHandlerInfo.handler;
			callHook(handler, "redirect", resolvedHandlerInfo.context, payload);
		}

		// Proceed after ensuring that the redirect hook
		// didn't abort this transition by transitioning elsewhere.
		return innerShouldContinue().then(resolveOneHandlerInfo);
	}

	function resolveOneHandlerInfo() {
		if (payload.resolveIndex === currentState.handlerInfos.length) {
			// This is is the only possible
			// fulfill value of TransitionState#resolve
			return {
				error: null,
				state: currentState
			};
		}

		var handlerInfo = currentState.handlerInfos[payload.resolveIndex];
		return handlerInfo.resolve(innerShouldContinue, payload).then(proceed);
	}

	return Promise.resolve(null).then(resolveOneHandlerInfo)["catch"](handleError);
};

RouterState.prototype.setup = function (name, params) {
	var index = this.handlerInfos.length;

	var oldState = this.router.state;
	var oldHandlerInfo = oldState.handlerInfos[index];

	var handler = this.router.handlerFor(name);
	var newHandlerInfo = handlerInfoFactory("param", {
		name: name,
		handler: handler,
		params: params
	});

	if (this.differentToPrevious || newHandlerInfo.shouldSupercede(oldHandlerInfo)) {
		this.differentToPrevious = true;
		this.add(newHandlerInfo);
	} else {
		this.add(oldHandlerInfo);
	}
	return this;
};

module.exports = RouterState;
