var HandlerInfo = require("./handler-info");
var assign = require("lodash/assign");

var utils = require("../utils");
var resolveHook = utils.resolveHook;

function UnresolvedHandlerInfoByParam(props) {
	HandlerInfo.call(this, props);
	this.params = props && props.params || {};
}

UnresolvedHandlerInfoByParam.prototype = Object.create(HandlerInfo.prototype);

UnresolvedHandlerInfoByParam.prototype.getModel = function (payload) {
	var fullParams = this.params;
	if (payload && payload.queryParams) {
		fullParams = assign({}, this.params);
		fullParams.queryParams = payload.queryParams;
	}

	var handler = this.handler;
	var hookName = resolveHook(handler, "deserialize") || resolveHook(handler, "model");

	return this.runSharedModelHook(payload, hookName, [fullParams]);
};

module.exports = UnresolvedHandlerInfoByParam;
