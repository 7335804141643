var Router = require("ampersand-router");
var RouterState = require("./router-state");
var assign = require("lodash/assign");
var isFunction = require("lodash/isFunction");
var isRegExp = require("lodash/isRegExp");
var parseQueryString = require("qs/lib/parse");

var transition = require("./transition");
var TransitionAborted = transition.TransitionAborted;
var Transition = transition.Transition;

var utils = require("./utils");
var getChangeList = utils.getChangeList;
var callHook = utils.callHook;

var slice = Array.prototype.slice;

var defaultActionHandlers = {

	willResolveModel(transition, originRoute) {
		transition.router._scheduleLoadingEvent(transition, originRoute);
	}

};

function fireQueryParamDidChange(router, newState, changeList) {
	// If queryParams changed trigger event
	if (changeList) {
		// This is a little hacky but we need some way of storing
		// changed query params given that no activeTransition
		// is guaranteed to have occurred.
		router._changedQueryParams = changeList.all;
		router.sendAction(newState.handlerInfos, true, ["queryParamsDidChange", changeList.changed, changeList.all, changeList.removed]);
		router._changedQueryParams = null;
	}
}

function finalizeQueryParamChange(router, resolvedHandlers, newQueryParams, transition) {
	// We fire a finalizeQueryParamChange event which
	// gives the new route hierarchy a chance to tell
	// us which query params it's consuming and what
	// their final values are. If a query param is
	// no longer consumed in the final route hierarchy,
	// its serialized segment will be removed
	// from the URL.

	for (var k in newQueryParams) {
		if (newQueryParams.hasOwnProperty(k) &&
				(newQueryParams[k] === null || newQueryParams[k] === undefined)) {
			delete newQueryParams[k];
		}
	}

	var finalQueryParamsArray = [];
	router.sendAction(resolvedHandlers, true, ["finalizeQueryParamChange", newQueryParams, finalQueryParamsArray, transition]);

	// if (transition) {
	// 	transition._visibleQueryParams = {};
	// }

	var finalQueryParams = {};
	for (var i = 0, len = finalQueryParamsArray.length; i < len; ++i) {
		var qp = finalQueryParamsArray[i];
		finalQueryParams[qp.key] = qp.value;
		// if (transition && qp.visible !== false) {
		// 	transition._visibleQueryParams[qp.key] = qp.value;
		// }
	}
	return finalQueryParams;
}

/**
@private
Takes an Array of `HandlerInfo`s, figures out which ones are
exiting, entering, or changing contexts, and calls the
proper handler hooks.
For example, consider the following tree of handlers. Each handler is
followed by the URL segment it handles.
```
|~index ("/")
| |~posts ("/posts")
| | |-showPost ("/:id")
| | |-newPost ("/new")
| | |-editPost ("/edit")
| |~about ("/about/:id")
```
Consider the following transitions:
1. A URL transition to `/posts/1`.
	1. Triggers the `*model` callbacks on the
	`index`, `posts`, and `showPost` handlers
	2. Triggers the `enter` callback on the same
	3. Triggers the `setup` callback on the same
2. A direct transition to `newPost`
	1. Triggers the `exit` callback on `showPost`
	2. Triggers the `enter` callback on `newPost`
	3. Triggers the `setup` callback on `newPost`
3. A direct transition to `about` with a specified
	context object
	1. Triggers the `exit` callback on `newPost`
	and `posts`
	2. Triggers the `serialize` callback on `about`
	3. Triggers the `enter` callback on `about`
	4. Triggers the `setup` callback on `about`
@param {Router} transition
@param {TransitionState} newState
**/
function setupContexts(router, newState, transition) {
	var partition = partitionHandlers(router.state, newState);
	var i, l, handler;

	for (i = 0, l = partition.exited.length; i < l; i++) {
		handler = partition.exited[i].handler;
		callHook(handler, "reset", true, transition);
		delete handler.context;
		callHook(handler, "exit", transition);
	}

	var oldState = router.state;
	router.state = newState;
	var currentHandlerInfos = partition.unchanged.slice();

	try {
		for (i = 0, l = partition.reset.length; i < l; i++) {
			handler = partition.reset[i].handler;
			callHook(handler, "reset", false, transition);
		}

		for (i = 0, l = partition.updatedContext.length; i < l; i++) {
			handlerEnteredOrUpdated(currentHandlerInfos, partition.updatedContext[i], false, transition);
		}

		for (i = 0, l = partition.entered.length; i < l; i++) {
			handlerEnteredOrUpdated(currentHandlerInfos, partition.entered[i], true, transition);
		}
	} catch(e) {
		router.state = oldState;
		throw e;
	}

	router.state.queryParams = finalizeQueryParamChange(router, currentHandlerInfos, newState.queryParams, transition);
}

/**
@private
Helper method used by setupContexts. Handles errors or redirects
that may happen in enter/setup.
**/
function handlerEnteredOrUpdated(currentHandlerInfos, handlerInfo, enter, transition) {
	var handler = handlerInfo.handler;
	var context = handlerInfo.context;

	if (enter) {
		callHook(handler, "enter", transition);
	}
	if (transition && transition.isAborted) {
		throw new TransitionAborted();
	}

	handler.context = context;
	callHook(handler, "setup", context, transition);
	if (transition && transition.isAborted) {
		throw new TransitionAborted();
	}

	currentHandlerInfos.push(handlerInfo);

	return true;
}

/**
@private
This function is called when transitioning from one URL to
another to determine which handlers are no longer active,
which handlers are newly active, and which handlers remain
active but have their context changed.
Take a list of old handlers and new handlers and partition
them into four buckets:
* unchanged: the handler was active in both the old and
new URL, and its context remains the same
* updated context: the handler was active in both the
old and new URL, but its context changed. The handler's
`setup` method, if any, will be called with the new
context.
* exited: the handler was active in the old URL, but is
no longer active.
* entered: the handler was not active in the old URL, but
is now active.
The PartitionedHandlers structure has four fields:
* `updatedContext`: a list of `HandlerInfo` objects that
represent handlers that remain active but have a changed
context
* `entered`: a list of `HandlerInfo` objects that represent
handlers that are newly active
* `exited`: a list of `HandlerInfo` objects that are no
longer active.
* `unchanged`: a list of `HanderInfo` objects that remain active.
@param {Array[HandlerInfo]} oldHandlers a list of the handler
information for the previous URL (or `[]` if this is the
first handled transition)
@param {Array[HandlerInfo]} newHandlers a list of the handler
information for the new URL
@return {Partition}
**/
function partitionHandlers(oldState, newState) {
	var oldHandlers = oldState.handlerInfos;
	var newHandlers = newState.handlerInfos;

	var handlers = {
		updatedContext: [],
		exited: [],
		entered: [],
		unchanged: []
	};

	var handlerChanged;
	var contextChanged = false;
	var i, l;

	for (i = 0, l = newHandlers.length; i < l; i++) {
		var oldHandler = oldHandlers[i],
			newHandler = newHandlers[i];

		if (!oldHandler || oldHandler.handler !== newHandler.handler) {
			handlerChanged = true;
		}

		if (handlerChanged) {
			handlers.entered.push(newHandler);
			if (oldHandler) {
				handlers.exited.unshift(oldHandler);
			}
		} else if (contextChanged || oldHandler.context !== newHandler.context) {
			contextChanged = true;
			handlers.updatedContext.push(newHandler);
		} else {
			handlers.unchanged.push(oldHandler);
		}
	}

	for (i = newHandlers.length, l = oldHandlers.length; i < l; i++) {
		handlers.exited.unshift(oldHandlers[i]);
	}

	handlers.reset = handlers.updatedContext.slice();
	handlers.reset.reverse();

	return handlers;
}

function finalizeTransition(transition, newState) {
	try {
		var router = transition.router;
		var handlerInfos = newState.handlerInfos;

		// Run all the necessary enter/setup/exit hooks
		setupContexts(router, newState, transition);

		// Check if a redirect occurred in enter/setup
		if (transition.isAborted) {
			// TODO: cleaner way? distinguish b/w targetHandlerInfos?
			//router.state.handlerInfos = router.currentHandlerInfos;
			return Promise.reject(new TransitionAborted());
		}

		transition.isActive = false;
		router.activeTransition = null;

		router.sendAction(router.state.handlerInfos, true, ["didTransition"]);

		if (router.didTransition) {
			router.didTransition(router.state.handlerInfos);
		}

		// Resolve with the final handler.
		return handlerInfos[handlerInfos.length - 1].handler;

	} catch (e) {
		if (!(e instanceof TransitionAborted)) {
			var infos = transition.state.handlerInfos;
			transition.send(true, "error", e, transition, infos[infos.length - 1].handler);
			transition.abort();
		}
		throw e;
	}
}

function notifyExistingHandlers(router, newState, newTransition) {
	var oldHandlers = router.state.handlerInfos;
	router.sendAction(oldHandlers, true, ["willTransition", newTransition]);
	if (router.willTransition) {
		router.willTransition(oldHandlers, newState.handlerInfos, newTransition);
	}
}

function handlerInfosEqual(handlerInfos, otherHandlerInfos) {
	if (handlerInfos.length !== otherHandlerInfos.length) {
		return false;
	}
	for (var i = 0, l = handlerInfos.length; i < l; ++i) {
		if (handlerInfos[i] !== otherHandlerInfos[i]) {
			return false;
		}
	}
	return true;
}

function doTransition(router, newState) {
	var wasTransitioning = !!router.activeTransition;
	var oldState = wasTransitioning ? router.activeTransition.state : router.state;
	var queryParamChangeList = getChangeList(oldState.queryParams, newState.queryParams);
	var newTransition;

	if (handlerInfosEqual(newState.handlerInfos, oldState.handlerInfos)) {
		if (queryParamChangeList) {
			newTransition = router.queryParamsTransition(queryParamChangeList, wasTransitioning, oldState, newState);
			if (newTransition) {
				return newTransition;
			}
		}

		// No need to create a new transition.
		return router.activeTransition || new Transition(router);
	}

	// Create a new transition to the new route.
	newTransition = new Transition(router, newState);

	// Abort any previously active transition
	if (router.activeTransition) {
		router.activeTransition.abort();
	}
	router.activeTransition = newTransition;

	newTransition.promise = newTransition.promise.then(function (result) {
		return finalizeTransition(newTransition, result.state);
	});

	if (!wasTransitioning) {
		notifyExistingHandlers(router, newState, newTransition);
	}

	fireQueryParamDidChange(router, newState, queryParamChangeList);

	return newTransition;
}

function findTargetRoute(handlerInfos, context, targetName) {
	var route, i, l, defaultTarget;
	for (i = 0, l = handlerInfos.length; i < l; i++) {
		route = handlerInfos[i].handler;
		if (targetName) {
			if (targetName === handlerInfos[i].name) {
				return route;
			}
		} else {
			if (route === context) {
				return defaultTarget || null;
			}
		}
		defaultTarget = route;
	}
	throw new Error("Could not find target route " +
			(targetName ? "with name '" + targetName + "'." : "for '" + context.cid + "'."));
}

module.exports = Router.extend({

	constructor: function () {
		Router.apply(this, arguments);
		this.state = new RouterState(this);
	},

	createHandlerFor: function (name) {
		var Factory = this.handlers && this.handlers[name];
		var handler;
		if (!Factory) {
			throw new Error("Missing handler for '" + name + "'.");
		}
		handler = new Factory({parent: this});
		return handler;
	},

	didTransition: function () {
		this.isFastBoot = false;
		this._cancelSlowTransitionTimer();
	},

	execute: function (callback, args, name) {
		var router = this;
		var queryParams, state;
		if (callback) {
			queryParams = parseQueryString(args.pop());
			args.push(queryParams);
			state = callback.apply(this, args);
			if (state instanceof RouterState) {
				assign(state.queryParams, queryParams);
				return Promise.resolve(doTransition(router, state));
			} else {
				router.isFastBoot = false;
				return Promise.resolve(router);
			}
		}
		return Promise.reject(new Error("Missing route callback for '" + name + "'."));
	},

	handlerFor: function (name) {
		var handlers = this._handlers || (this._handlers = {});
		var handler = handlers[name];
		if (!handler) {
			handler = handlers[name] = this.createHandlerFor(name);
		}
		return handlers[name];
	},

	hasRoute: function (fragment) {
		fragment = this.history.getFragment(fragment);
		return this.history.handlers.some(function (handler) {
			return handler.route.test(fragment);
		});
	},

	navigate: function (newUrl, options) {
		var router = this;
		var fragment = this.history.getFragment(newUrl);
		var callback;

		var hasRoute = this.history.handlers.some(function (handler) {
			if (handler.route.test(fragment)) {
				callback = handler.callback;
				return true;
			}
		});

		if (hasRoute) {
			return Promise.resolve(callback(fragment)).then(function () {
				router.history.navigate(newUrl, assign({}, options, {trigger: false}));
				return router;
			});
		}

		return Promise.reject(new Error("No route did match '" + newUrl + "'."));
	},

	queryParamsTransition: function (changeList, wasTransitioning, oldState, newState) {
		var router = this;

		fireQueryParamDidChange(this, newState, changeList);

		if (!wasTransitioning && this.activeTransition) {
			// One of the handlers in queryParamsDidChange
			// caused a transition. Just return that transition.
			return this.activeTransition;

		} else {
			// Running queryParamsDidChange didn't change anything.
			// Just update query params and be on our way.

			// We have to return a noop transition that will
			// perform a URL update at the end. This gives
			// the user the ability to set the url update
			// method (default is replaceState).
			var newTransition = new Transition(this);
			newTransition.queryParamsOnly = true;

			oldState.queryParams = finalizeQueryParamChange(this, newState.handlerInfos, newState.queryParams, newTransition);

			newTransition.promise = newTransition.promise.then(function (result) {
				if (router.didTransition) {
					router.didTransition(router.currentHandlerInfos);
				}
				return result;
			});
			return newTransition;
		}
	},

	redirectTo: function (newUrl) {
		return this.navigate(newUrl, {replace: true});
	},

	refresh: function (pivotHandler) {
		var oldState = this.activeTransition ? this.activeTransition.state : this.state;
		var handlerInfos = oldState.handlerInfos;
		var newState = new RouterState(this);
		var invalid = false;
		var i, l, handlerInfo;

		for (i = 0, l = handlerInfos.length; i < l; i++) {
			handlerInfo = handlerInfos[i];
			if (invalid || handlerInfo.handler === pivotHandler) {
				invalid = true;
				newState.add(handlerInfo.getUnresolved());
			} else {
				newState.add(handlerInfo);
			}
		}

		newState.queryParams = this._changedQueryParams || oldState.queryParams;

		return doTransition(this, newState);
	},

	removeRouteComponent: function (route, component, options) {
		options || (options = {});
		var targetRoute = findTargetRoute(this.state.handlerInfos, route, options.into);
		var region;

		if (targetRoute) {
			region = targetRoute.main[options.region || "main"];
			if (!region) {
				throw new Error("Could not find region that route’s component should be renderd into.");
			}
			if (region.component !== component) {
				throw new Error("Current component in region does not match component to be removed.");
			}
			region.clear();

		} else {
			// This is the top level component
			component.remove();
		}
		return this;
	},

	renderRouteComponent: function (route, component, options) {
		options || (options = {});
		var targetRoute = findTargetRoute(this.state.handlerInfos, route, options.into);
		var region, container;

		if (targetRoute) {
			region = targetRoute.main[options.region || "main"];
			if (!region) {
				throw new Error("Could not find region that route’s component should be renderd into.");
			}
			region[this.isFastBoot ? "mountComponent" : "show"](component);

		} else {
			// This is the top level component
			container = this.rootSelector && document.querySelector(this.rootSelector) || document.body;
			if (this.isFastBoot && typeof component.mount === "function") {
				component.mount(container);
			} else {
				component.el = container;
			}
			if (!this.isFastBoot) {
				component.render();
			}
		}
		return this;
	},

	route: function (route, name, callback) {
		if (!isRegExp(route)) {
			route = this._routeToRegExp(route);
		}
		if (isFunction(name)) {
			callback = name;
			name = "";
		}
		if (!callback) {
			callback = this[name];
		}
		var router = this;
		this.history.route(route, function (fragment) {
			var args = router._extractParameters(route, fragment);
			return router.execute(callback, args, name).then(function (result) {
				router.trigger.apply(router, ["route:" + name].concat(args));
				router.trigger("route", name, args);
				router.history.trigger("route", router, name, args);
				return result;
			});
		});
		return this;
	},

	sendAction: function (handlerInfos, ignoreFailure, args) {
		var name = args.shift();

		if (!handlerInfos) {
			if (ignoreFailure) {
				return;
			}
			throw new Error("Could not trigger event '" + name + "'. There are no active handlers.");
		}

		var eventWasHandled = false;

		for (var i = handlerInfos.length - 1; i >= 0; i--) {
			var handlerInfo = handlerInfos[i];
			var handler = handlerInfo.handler;
			if (handler.actions && handler.actions[name]) {
				if (handler.actions[name].apply(handler, args) === true) {
					eventWasHandled = true;
				} else {
					return;
				}
			}
		}

		if (defaultActionHandlers[name]) {
			defaultActionHandlers[name].apply(null, args);
			return;
		}

		if (!eventWasHandled && !ignoreFailure) {
			throw new Error("Nothing handled the event '" + name + "'.");
		}
	},

	send: function () {
		var args = slice.call(arguments);
		this.sendAction(this.state.handlerInfos, false, args);
	},

	setup: function (name, params) {
		var state = new RouterState(this);
		return state.setup(name, params);
	},

	start: function (options) {
		this.isFastBoot = !!(options && options.isFastBoot);
		this.history.start(options);
		return this;
	},

	stop: function () {
		this.history.stop();
		return this;
	},

	transitionTo: function (name) {
		var args = slice.call(arguments, 1);
		if (typeof this[name] !== "function") {
			return Promise.reject(new Error("Missing route callback for '" + name + "'."));
		}
		return doTransition(this, this[name].apply(this, args));
	},

	_cancelSlowTransitionTimer: function () {
		if (this._slowTransitionTimer) {
			window.clearTimeout(this._slowTransitionTimer);
		}
		this._slowTransitionTimer = null;
	},

	_handleSlowTransition: function (transition, originRoute) {
		if (!this.activeTransition) {
			return;
		}
		transition.send(true, "loading", transition, originRoute);
	},

	_scheduleLoadingEvent: function (transition, originRoute) {
		var router = this;
		if (!router._slowTransitionTimer) {
			router._slowTransitionTimer = window.setTimeout(function () {
				router._slowTransitionTimer = null;
				router._handleSlowTransition(transition, originRoute);
			}, 0);
		}
	}

});
