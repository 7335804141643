
function TransitionAborted(message) {
	this.message = (message || "TransitionAborted");
	this.name = "TransitionAborted";
}

exports.TransitionAborted = TransitionAborted;

function Transition(router, state, error) {
	var transition = this;

	function checkForAbort() {
		if (transition.isAborted) {
			return Promise.reject(undefined);
		}
	}

	this.router = router;
	this.state = state || router.state;
	this.resolvedModels = {};
	this.queryParams = {};

	if (error) {
		this.promise = Promise.reject(error);
		this.error = error;
		return;
	}

	if (state) {
		this.params = state.params;
		this.queryParams = state.queryParams;
		this.handlerInfos = state.handlerInfos;

		this.sequence = Transition.currentSequence++;
		this.promise = state.resolve(checkForAbort, transition)["catch"](function (result) {
			if (result.wasAborted || transition.isAborted) {
				return Promise.reject(new TransitionAborted());
			} else {
				transition.send("error", result.error, transition, result.handlerWithError);
				transition.abort();
				return Promise.reject(result.error);
			}
		});
		return;
	}

	this.promise = Promise.resolve(this.state);
}

Transition.currentSequence = 0;

Transition.prototype.isActive = true;
Transition.prototype.isTransition = true;

Transition.prototype.abort = function () {
	if (this.isAborted) {
		return this;
	}
	this.isActive = false;
	this.isAborted = true;
	this.router.activeTransition = null;
	return this;
};

Transition.prototype.catch = function (onRejected) {
	return this.promise.catch(onRejected);
};

Transition.prototype.isExiting = function (handler) {
	var handlerInfos = this.handlerInfos;
	for (var i = 0, l = handlerInfos.length; i < l; ++i) {
		var handlerInfo = handlerInfos[i];
		if (handlerInfo.name === handler || handlerInfo.handler === handler) {
			return false;
		}
	}
	return true;
};

Transition.prototype.then = function (onFulfilled, onRejected) {
	return this.promise.then(onFulfilled, onRejected);
};

Transition.prototype.send = function (ignoreFailure) {
	var args = Array.prototype.slice.call(arguments);
	if (typeof ignoreFailure === "boolean") {
		args.shift();
	} else {
		// Throw errors on unhandled trigger events by default
		ignoreFailure = false;
	}
	this.router.sendAction(this.handlerInfos.slice(0, this.resolveIndex + 1), ignoreFailure, args);
};

exports.Transition = Transition;
