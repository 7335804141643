var HandlerInfo = require("./handler-info");

var utils = require("../utils");
var isParam = utils.isParam;

function UnresolvedHandlerInfoByObject(props) {
	HandlerInfo.call(this, props);
	props || (props = {});
	this.names = props.names || [];
	this.context = props.context;
}

UnresolvedHandlerInfoByObject.prototype = Object.create(HandlerInfo.prototype);

UnresolvedHandlerInfoByObject.prototype.getModel = function () {
	return Promise.resolve(this.context);
};

UnresolvedHandlerInfoByObject.prototype.serialize = function (_model) {
	var model = _model || this.context;
	var names = this.names;
	var handler = this.handler;
	var serializer = this.serializer || (handler && handler.serialize);
	var object = {};
	var name;

	if (isParam(model)) {
		object[names[0]] = model;
		return object;
	}

	// Use custom serialize if it exists.
	if (serializer) {
		return serializer(model, names);
	}

	if (names.length !== 1) {
		return;
	}

	name = names[0];

	if (/_id$/.test(name)) {
		object[name] = model.id;
	} else {
		object[name] = model;
	}
	return object;
};

module.exports = UnresolvedHandlerInfoByObject;
