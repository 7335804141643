import Component from "../../lib/components/component";
import Slideshow from "../slideshow";
import Slide from "./slide";
import dom from "ampersand-dom";
import {ImageAssetCollection} from "../../models/image-asset";

const toggleClass = (el, className, state) => {
	state = state === undefined ? !dom.hasClass(className) : state;
	dom[state ? "addClass" : "removeClass"](el, className);
	return el;
};

export const Slides = Slideshow.extend({

	props: {
		alignment: "string",
		scale: "string",
		style: "string"
	},

	component: {
		type: Slide,
		bindings: {
			alignment: "alignment",
			scale: "scale",
			style: "style"
		}
	}

});

export default Component.extend({

	props: {
		/**
		@property alignment
		@type string
		**/
		alignment: {
			type: "string",
			required: true,
			default: "middle"
		},

		/**
		@property caption
		@type string
		**/
		caption: {
			type: "string"
		},

		/**
		@property index
		@type number
		**/
		index: {
			type: "number",
			required: true,
			default: 0
		},

		/**
		@property scaleRatio
		@type number
		**/
		scaleRatio: {
			type: "number",
			required: true,
			default: 0.5625
		},

		/**
		@property scale
		@type string
		**/
		scale: {
			type: "string",
			required: true,
			default: "normal"
		},

		/**
		@property style
		@type string
		**/
		style: {
			type: "string",
			required: true,
			default: "scaled"
		}
	},

	derived: {
		position: {
			deps: ["index"],
			fn() {
				return this.index + 1;
			}
		}
	},

	collections: {
		images: ImageAssetCollection
	},

	components: {
		slideshow: {
			type: Slides,
			hook: "slideshow",
			bindings: {
				alignment: "alignment",
				collection: "images",
				index: "index",
				onChange: "_onSlideshowChange",
				scale: "scale",
				style: "style"
			}
		}
	},

	bindings: {
		isFirst: {
			type: "booleanClass",
			name: "slideshow--is-first"
		},
		isLast: {
			type: "booleanClass",
			name: "slideshow--is-last"
		},
		position: {
			type: "text",
			hook: "slideshow-position"
		}
	},

	events: {
		"click [data-action]": "_onActionClick"
	},

	actions: {

		nextSlide() {
			const total = this.images.length || 0;
			this.index = (this.index < total - 1) ? this.index + 1 : 0;
		},

		prevSlide() {
			const total = this.images.length || 0;
			this.index = (this.index > 0) ? this.index - 1 : total - 1;
		},

		showSlide(index) {
			index = typeof index === "string" ? parseInt(index, 10) : index;
			const total = this.images.length || 0;
			if (typeof index === "number" && index >= 0 && index < total) {
				this.index = index;
			}
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.on("change:index", this._handleIndexChange);
	},

	toggleNav(index, state) {
		const el = this.query("[data-nav-index=\"" + index + "\"]");
		if (el) {
			toggleClass(el, "isActive", state);
		}
		return this;
	},

	_handleIndexChange(target, newVal) {
		const prevVal = target.previous("index");
		if (prevVal !== null || prevVal !== undefined) {
			this.toggleNav(prevVal, false);
		}
		if (newVal !== null || newVal !== undefined) {
			this.toggleNav(newVal, true);
		}
	},

	_onActionClick(e) {
		if (e.defaultPrevented) {
			return;
		}
		const target = e.delegateTarget || e.currentTarget;
		const action = target.getAttribute("data-action");
		this.send.apply(this, action.split(":"));
		e.preventDefault();
	},

	_onSlideshowChange(index) {
		this.index = index;
	}

});
