var assign = require("lodash/assign");
var classExtend = require("ampersand-class-extend");

function mergeParams(options, params) {
	var result = options ? assign({}, options) : {};
	if (params) {
		result.data = assign({}, params, result.data);
	}
	return result;
}

function createCollectionForStore(store, type) {
	var Collection = store && store.collections && store.collections[type];
	if (!Collection) {
		throw new Error("Missing collection configuration for type '" + type + "'.");
	}
	return new Collection(null, {store: store});
}

function getModelType(attrs) {
	var type, typeAttr;
	if (typeof attrs.getType === "function") {
		type = attrs.getType();
	} else {
		typeAttr = attrs.typeAttribute || "type";
		type = typeof attrs.get === "function" ? attrs.get(typeAttr) : attrs[typeAttr];
	}
	if (!type) {
		throw new Error("Missing 'type' attribute on model.");
	}
	return type;
}

function Store(data, options = {}) {
	options || (options = {});
	this.collections = assign({}, this.collections, options.collections || {});
	this.initialize.apply(this, arguments);
	if (data) {
		this.reset(data, assign({silent: true}, options));
	}
}

assign(Store.prototype, {

	collections: {},

	initialize: function () {},

	add: function (type, data, options) {
		return this.collectionFor(type).add(data, assign({
			merge: true,
			parse: true,
			store: this
		}, options));
	},

	all: function (type) {
		if (this.hasCollection(type)) {
			return this.collectionFor(type);
		}
		return null;
	},

	collectionFor: function (type) {
		var collections = this._collections || (this._collections = {});
		if (!collections[type]) {
			collections[type] = createCollectionForStore(this, type);
		}
		return collections[type];
	},

	fetchAll: function (type, options) {
		var opts = mergeParams(options, this.params);
		var collection = this.collectionFor(type);
		return new Promise(function (resolve, reject) {
			collection.fetch(assign(opts, {
				success: function (collection) {
					resolve(collection);
				},
				error: function (collection, resp) {
					reject(new Error(resp.statusText));
				}
			}));
		});
	},

	fetchById: function (type, id, options) {
		var opts = mergeParams(options, this.params);
		var collection = this.collectionFor(type);
		return new Promise(function (resolve, reject) {
			collection.fetchById(id, opts, function (err, model) {
				if (err) {
					reject(err);
				} else {
					resolve(model);
				}
			});
		});
	},

	filter: function (type, query, filter) {
		var promise, collection;

		if (arguments.length === 2) {
			filter = query;
			query = undefined;
		}

		if (query) {
			promise = this.query(type, query);
		}

		collection = this.createFilteredCollection(type, filter);
		promise = promise || Promise.resolve(collection);

		return promise.then(function () {
			return collection;
		});
	},

	findAll: function (type, options) {
		var collection = this.all(type);
		if (collection) {
			return Promise.resolve(collection);
		}
		return this.fetchAll(type, options);
	},

	findById: function (type, id, options) {
		var model = this.get(type, id, options);
		if (model) {
			return Promise.resolve(model);
		}
		return this.fetchById(type, id, options);
	},

	find: function (type, id) {
		if (typeof id === "number" || typeof id === "string") {
			return this.findById(type, id);
		}
		return this.findAll(type, id);
	},

	hasModel: function (type, model) {
		return !!this.get(type, model);
	},

	hasCollection: function (type) {
		return (this._collections && type in this._collections);
	},

	get: function (type, id, options) {
		if (this.hasCollection(type)) {
			return this.collectionFor(type).get(id, options) || null;
		}
		return null;
	},

	modelFor: function (type, id, options) {
		var collection = this.collectionFor(type);
		return collection.get(id, options) || collection.add({type: type, id: id}, {store: this});
	},

	query: function (type, params, options) {
		var opts = mergeParams(options, this.params);
		var collection = createCollectionForStore(this, type);
		opts.data = assign({}, opts.data, params);
		return new Promise(function (resolve, reject) {
			collection.fetch(assign(opts, {
				success: function (collection) {
					resolve(collection);
				},
				error: function (collection, resp) {
					reject(new Error(resp.statusText));
				}
			}));
		});
	},

	register: function (model) {
		var type = getModelType(model);
		var collection = this.collectionFor(type);

		// No need to register if the model has a reference to this collection
		// because it is already in store, or is just beeing added.
		if (model.collection === collection) {
			return model;
		}

		model.collection = collection;
		return this.collectionFor(type).add(model);
	},

	reset: function (resources, options) {
		if (!resources) {
			return null;
		}
		var result = {};
		for (var key in resources) {
			if (this.collections.hasOwnProperty(key)) {
				result[key] = this.collectionFor(key).reset(resources[key], options);
			}
		}
		return result;
	},

	setParam: function (name, value) {
		if (!this.params) {
			this.params = {};
		}
		this.params[name] = value;
		return this;
	},

	unregister: function (model) {
		var type = getModelType(model);

		if (!this.hasCollection(type)) {
			return model;
		}

		return this.collectionFor(type).remove(model);
	}

});

Store.extend = classExtend;

module.exports = Store;
