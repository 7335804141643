var assign = require("lodash/assign");

function coerceQueryParamsToString(queryParams) {
	for (var key in queryParams) {
		if (typeof queryParams[key] === "number") {
			queryParams[key] = "" + queryParams[key];
		} else if (Array.isArray(queryParams[key])) {
			for (var i = 0, l = queryParams[key].length; i < l; i++) {
				queryParams[key][i] = "" + queryParams[key][i];
			}
		}
	}
}

function getChangeList(oldObject, newObject) {
	var didChange = false;
	var results = {
		all: {},
		changed: {},
		removed: {}
	};
	var key;

	assign(results.all, newObject);

	coerceQueryParamsToString(oldObject);
	coerceQueryParamsToString(newObject);

	// Calculate removals
	for (key in oldObject) {
		if (oldObject.hasOwnProperty(key)) {
			if (!newObject.hasOwnProperty(key)) {
				didChange = true;
				results.removed[key] = oldObject[key];
			}
		}
	}

	// Calculate changes
	for (key in newObject) {
		if (newObject.hasOwnProperty(key)) {
			if (Array.isArray(oldObject[key]) && Array.isArray(newObject[key])) {
				if (oldObject[key].length !== newObject[key].length) {
					results.changed[key] = newObject[key];
					didChange = true;
				} else {
					for (var i = 0, l = oldObject[key].length; i < l; i++) {
						if (oldObject[key][i] !== newObject[key][i]) {
							results.changed[key] = newObject[key];
							didChange = true;
						}
					}
				}
			}
			else {
				if (oldObject[key] !== newObject[key]) {
					results.changed[key] = newObject[key];
					didChange = true;
				}
			}
		}
	}

	return didChange && results;
}
exports.getChangeList = getChangeList;

function isParam(object) {
	return (typeof object === "string" || object instanceof String || typeof object === "number" || object instanceof Number);
}
exports.isParam = isParam;

function resolveHook(obj, hookName) {
	if (!obj) {
		return;
	}
	var underscored = "_" + hookName;
	return obj[underscored] && underscored || obj[hookName] && hookName;
}
exports.resolveHook = resolveHook;

function callHook(obj, _hookName, arg1, arg2) {
	var hookName = resolveHook(obj, _hookName);
	return hookName && obj[hookName].call(obj, arg1, arg2);
}
exports.callHook = callHook;

function applyHook(obj, _hookName, args) {
	var hookName = resolveHook(obj, _hookName);
	if (hookName) {
		if (args.length === 0) {
			return obj[hookName].call(obj);
		} else if (args.length === 1) {
			return obj[hookName].call(obj, args[0]);
		} else if (args.length === 2) {
			return obj[hookName].call(obj, args[0], args[1]);
		} else {
			return obj[hookName].apply(obj, args);
		}
	}
}
exports.applyHook = applyHook;
