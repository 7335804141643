var assign = require("lodash/assign");
var utils = require("../utils");
var applyHook = utils.applyHook;

function paramsMatch(a, b) {
	if ((!a) ^ (!b)) {
		// Only one is null.
		return false;
	}

	if (!a) {
		// Both must be null.
		return true;
	}

	// Note: this assumes that both params have the same
	// number of keys, but since we're comparing the
	// same handlers, they should.
	for (var k in a) {
		if (a.hasOwnProperty(k) && a[k] !== b[k]) {
			return false;
		}
	}
	return true;
}

function HandlerInfo(props) {
	assign(this, props);
}

HandlerInfo.prototype.getUnresolved = function () {
	return this;
};

HandlerInfo.prototype.serialize = function () {
	return this.params || {};
};

HandlerInfo.prototype.resolve = function (shouldContinue, payload) {
	var checkForAbort  = this.checkForAbort.bind(this, shouldContinue);
	var beforeModel    = this.runBeforeModelHook.bind(this, payload);
	var model          = this.getModel.bind(this, payload);
	var afterModel     = this.runAfterModelHook.bind(this, payload);
	var becomeResolved = this.becomeResolved.bind(this, payload);

	return Promise.resolve(undefined)
		.then(checkForAbort)
		.then(beforeModel)
		.then(checkForAbort)
		.then(model)
		.then(checkForAbort)
		.then(afterModel)
		.then(checkForAbort)
		.then(becomeResolved);
};

HandlerInfo.prototype.runBeforeModelHook = function (payload) {
	if (payload.send) {
		payload.send(true, "willResolveModel", payload, this.handler);
	}
	return this.runSharedModelHook(payload, "beforeModel", []);
};

HandlerInfo.prototype.runAfterModelHook = function (payload, resolvedModel) {
	// Stash the resolved model on the payload.
	// This makes it possible for users to swap out
	// the resolved model in afterModel.
	var name = this.name;
	this.stashResolvedModel(payload, resolvedModel);

	return this.runSharedModelHook(payload, "afterModel", [resolvedModel])
		.then(function () {
			// Ignore the fulfilled value returned from afterModel.
			// Return the value stashed in resolvedModels, which
			// might have been swapped out in afterModel.
			return payload.resolvedModels[name];
		});
};

HandlerInfo.prototype.runSharedModelHook = function (payload, hookName, args) {
	if (this.queryParams) {
		args.push(this.queryParams);
	}
	args.push(payload);

	var result = applyHook(this.handler, hookName, args);

	if (result && result.isTransition) {
		result = null;
	}

	return Promise.resolve(result);
};

// overridden by subclasses
HandlerInfo.prototype.getModel = null;

HandlerInfo.prototype.checkForAbort = function (shouldContinue, promiseValue) {
	return Promise.resolve(shouldContinue()).then(function () {
		// We don't care about shouldContinue's resolve value;
		// pass along the original value passed to this fn.
		return promiseValue;
	});
};

HandlerInfo.prototype.stashResolvedModel = function (payload, resolvedModel) {
	payload.resolvedModels = payload.resolvedModels || {};
	payload.resolvedModels[this.name] = resolvedModel;
};

HandlerInfo.prototype.becomeResolved = function (payload, resolvedContext) {
	var params = this.serialize(resolvedContext);

	if (payload) {
		this.stashResolvedModel(payload, resolvedContext);
		payload.params = payload.params || {};
		payload.params[this.name] = params;
	}

	return this.factory("resolved", {
		context: resolvedContext,
		name: this.name,
		handler: this.handler,
		params: params
	});
};

HandlerInfo.prototype.shouldSupercede = function (other) {
	// Prefer this newer handlerInfo over `other` if:
	// 1) The other one doesn't exist
	// 2) The names don't match
	// 3) This handler has a context that doesn't match
	//    the other one (or the other one doesn't have one).
	// 4) This handler has parameters that don't match the other.
	if (!other) {
		return true;
	}
	var contextsMatch = (other.context === this.context);
	return other.name !== this.name ||
			(this.hasOwnProperty("context") && !contextsMatch) ||
			(this.hasOwnProperty("params") && !paramsMatch(this.params, other.params));
};

module.exports = HandlerInfo;
