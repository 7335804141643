import Rect from  "./rect";

/**
@class ViewportData
@extends Rect
**/
export default Rect.extend({

	props: {
		/**
		@property orientation
		@type number
		@default 0
		**/
		orientation: {
			type: "number",
			required: true,
			default: 0
		}
	}

});
