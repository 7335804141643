var HandlerInfo = require("./handler-info");

function ResolvedHandlerInfo(props) {
	HandlerInfo.call(this, props);
}

ResolvedHandlerInfo.prototype = Object.create(HandlerInfo.prototype);

ResolvedHandlerInfo.prototype.isResolved = true;

ResolvedHandlerInfo.prototype.resolve = function (shouldContinue, payload) {
	// A ResolvedHandlerInfo just resolved with itself.
	if (payload && payload.resolvedModels) {
		payload.resolvedModels[this.name] = this.context;
	}
	return Promise.resolve(this);
};

ResolvedHandlerInfo.prototype.getUnresolved = function () {
	return this.factory("param", {
		name: this.name,
		handler: this.handler,
		params: this.params
	});
};

module.exports = ResolvedHandlerInfo;
