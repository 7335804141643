var State = require("ampersand-state");
var assign = require("lodash/assign");
var createBindingsStore = require("../state-bindings/create-bindings-store");
var mapStateToProps = require("../state-bindings/map-state-to-props");
var uniqueId = require("lodash/uniqueId");

var BaseState = State.extend({
	dataTypes: {
		collection: {
			set: function (newVal) {
				return {
					val: newVal,
					type: newVal && newVal.isCollection ? "collection" : typeof newVal
				};
			},
			compare: function (collectionA, collectionB) {
				return collectionA === collectionB;
			}
		}
	}
});

function Route(attrs) {
	attrs = attrs || {};
	this.cid = uniqueId("route");
	var parent = attrs.parent;
	delete attrs.parent;
	BaseState.call(this, attrs, {init: false, parent: parent});
	this._initBindings();
	this.initialize.apply(this, arguments);
}

Route.prototype = Object.create(BaseState.prototype);

assign(Route.prototype, {

	createComponent: function (config) {
		config = config || {};
		var Factory = config.type;
		var bindings = config.bindings;
		var parse = config.parse;
		var props = typeof config.props === "function" ? config.props.call(this) : config.props;
		var initialProps = mapStateToProps(this, bindings, props);
		var component = new Factory(assign({parent: this, parse: parse}, initialProps));
		this.registerBindingsForComponent(component, bindings);
		return component;
	},

	navigate: function (fragment, options) {
		this.parent.navigate(fragment, options);
		return this;
	},

	redirectTo: function (fragment) {
		this.parent.redirectTo(fragment);
		return this;
	},

	refresh: function () {
		return this.parent.refresh(this);
	},

	registerBindingsForComponent: function (component, bindings) {
		if (!bindings) {
			return this;
		}
		var unsubscribe = this._componentBindings.add(component, bindings);
		component.once("remove", unsubscribe);
		return this;
	},

	reload: function () {
		this.parent.reload();
		return this;
	},

	removeComponent: function (component, options) {
		this.parent.removeRouteComponent(this, component, options);
		return this;
	},

	renderComponent: function (component, options) {
		this.parent.renderRouteComponent(this, component, options);
		return this;
	},

	routeFor: function (name) {
		return this.parent.handlerFor(name);
	},

	send: function (name) {
		if (this.parent && typeof this.parent.send === "function") {
			this.parent.send.apply(this.parent, arguments);
		} else {
			var args = Array.prototype.slice.call(arguments, 1);
			if (this.actions[name]) {
				return this.actions[name].apply(this, args);
			}
		}
	},

	_applyBindingsForKey: function (name) {
		this._componentBindings.update(name);
	},

	_initBindings: function () {
		this._componentBindings = createBindingsStore(this);

		this.on("all", function (eventName) {
			if (eventName.slice(0, 7) === "change:") {
				this._applyBindingsForKey(eventName.split(":")[1]);
			}
		});

		return this;
	}

});

Object.defineProperty(Route.prototype, "store", {
	get: function () {
		return this.parent && this.parent.store;
	}
});

Route.extend = BaseState.extend;

module.exports = Route;
