import Component from "../../lib/components/component";
import template from "./slide.hbs";
import {picturefillComponent} from "../../lib/utils";

export default Component.extend({

	props: {
		alignment: "string",
		model: "state",
		scale: "string",
		style: "string"
	},

	derived: {
		orientation: {
			deps: ["model.ratio", "scaleRatio", "style"],
			fn() {
				if (this.style === "cropped" || this.style === "fitted") {
					const ratio = this.model && this.model.ratio;
					return ratio && ratio < this.scaleRatio ? "wide" : "tall";
				}
				return null;
			}
		},
		ratio: {
			deps: ["model.ratio", "style"],
			fn() {
				if (this.style === "full" || this.style === "scaled") {
					return this.model && this.model.ratio && this.model.ratio * 100 || 0;
				}
				return null;
			}
		},
		scaleRatio: {
			deps: ["scale"],
			fn() {
				if (this.scale === "small") {
					return (1 / 3);
				}
				if (this.scale === "large") {
					return (2 / 3);
				}
				return (9 / 16);
			}
		},
		sizes: {
			deps: ["model.ratio", "scaleRatio", "style"],
			fn() {
				let small = this.style === "fitted" ? 94 : 98;
				let medium = this.style === "fitted" ? 70 : 74;
				let large = this.style === "fitted" ? 64 : 66;
				let contextRatio = 1;
				if (this.style === "fitted" && this.model && this.model.ratio && this.model.ratio > this.scaleRatio) {
					contextRatio = this.scaleRatio / this.model.ratio;
				}
				return "(min-width: 90.0625em) " + (large * contextRatio) + "vw, " +
						"(min-width: 60.375em) " + (medium * contextRatio) + "vw, " +
						(small * contextRatio) + "vw";
			}
		}
	},

	template: template,

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.on("change:model", this.rerender);
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		picturefillComponent(this);
		return this;
	},

	render() {
		Component.prototype.render.apply(this, arguments);
		picturefillComponent(this);
		return this;
	},

	rerender() {
		if (this.rendered) {
			this.render();
		}
		return this;
	},

});
