import Component from "../lib/components/component";
import Video from "./video";
import isMobile from "../lib/ua/is-mobile";

export default Component.extend({

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.initVideo();
	},

	initVideo() {
		const src = this.el && this.el.getAttribute("data-video");
		if (!isMobile && src) {
			this.video = this.createComponent({
				type: Video,
				props: {
					autoplay: true,
					controls: false,
					defaultMuted: true,
					loop: true,
					src
				}
			});
			this.video.render();
			const img = this.query("img");
			if (img) {
				img.parentNode.replaceChild(this.video.el, img);
			} else {
				this.el.appendChild(this.video.el);
			}
		}
		return this;
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.initVideo();
		return this;
	},

	remove() {
		this.video = undefined;
		return Component.prototype.remove.apply(this, arguments);
	},

	render() {
		Component.prototype.render.apply(this, arguments);
		this.initVideo();
		return this;
	}

});
