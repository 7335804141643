var Collection = require("ampersand-collection");
var assign = require("lodash/assign");

var find = Array.prototype.find || function (predicate, context) {
	if (this === null || this === undefined) {
		throw new TypeError("Array.prototype.find called on null or undefined");
	}
	if (typeof predicate !== "function") {
		throw new TypeError("predicate must be a function");
	}
	context || (context = this);
	var list = Object(this);
	var length = list.length >>> 0;
	var i, value;
	for (i = 0; i < length; i++) {
		value = list[i];
		if (predicate.call(context, value, i, list)) {
			return value;
		}
	}
	return undefined;
};

var StoreCollection = Collection.extend({

	constructor: function (models, options) {
		if (options && options.store) {
			this._store = options.store;
		}
		Collection.call(this, models, options);
	},

	find: function (predicate, context) {
		return find.call(this.models, predicate, context || this);
	},

	modelType: function (attrs) {
		var typeAttribute = this.model && this.model.prototype.typeAttribute;
		return attrs[typeAttribute || "type"];
	},

	_prepareModel: function (attrs, options) {
		var store, type, id, model, opts;

		if (!this.model || this.isModel(attrs)) {
			// Return the given object if no model class is defined
			// or it is already a model.
			return attrs;
		}

		store = this.store;
		if (store) {
			// Try to find the corresponding model in the store.
			type = this.modelType(attrs);
			id = attrs[this.mainIndex];
			model = type && id && store.get(type, id);
			if (model) {
				// Update existing model with new attrs
				model.set(attrs, options);
				// Return with the existing model found in the store.
				return model;
			}
		}

		// Create a new model if there is no store available
		// or no model was found through the store.
		opts = assign({store: store}, options);
		model = Collection.prototype._prepareModel.call(this, attrs, opts);

		if (store && model) {
			// Register the new model in the store, in case it was created
			// through a subcollection.
			store.register(model);
		}

		return model;
	}

});

Object.defineProperty(StoreCollection.prototype, "store", {
	get: function () {
		return this._store || this.parent &&
				(this.parent.store || this.parent.collection && this.parent.collection.store);
	}
});

module.exports = StoreCollection;
