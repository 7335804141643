import State from "ampersand-state";

/**
@class AccelerationData
@extends State
**/
export default State.extend({

	props: {
		/**
		@property x
		@type number
		@default 0
		**/
		x: {
			type: "number",
			required: true,
			default: 0
		},

		/**
		@property y
		@type number
		@default 0
		**/
		y: {
			type: "number",
			required: true,
			default: 0
		},

		/**
		@property z
		@type number
		@default 0
		**/
		z: {
			type: "number",
			required: true,
			default: 0
		}
	}

});
