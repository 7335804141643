import Component from "../lib/components/component";
import "mediaelement";

export default Component.extend({

	props: {
		/**
		@property autoplay
		@type boolean
		@default false
		**/
		autoplay: ["boolean", true, false],

		/**
		@property controls
		@type boolean
		@default true
		**/
		controls: ["boolean", true, true],

		/**
		@property isEnded
		@type boolean
		@default false
		**/
		isEnded: ["boolean", true, false],

		/**
		@property isLoaded
		@type boolean
		@default false
		**/
		isLoaded: ["boolean", true, false],

		/**
		@property isPlaying
		@type boolean
		@default false
		**/
		isPlaying: ["boolean", true, false],

		/**
		@property isWaiting
		@type boolean
		@default false
		**/
		isWaiting: ["boolean", true, false],

		/**
		@property playbackQuality
		@type number
		@default ""
		**/
		playbackQuality: ["string", true, ""],

		/**
		@property playbackRate
		@type number
		@default 1
		**/
		playbackRate: ["number", true, 1],

		/**
		@property volume
		@type number
		@default 1
		**/
		volume: ["number", true, 1]
	},

	constructor() {
		this._onEnded = this._onEnded.bind(this);
		this._onMediaElementSuccess = this._onMediaElementSuccess.bind(this);
		this._onPause = this._onPause.bind(this);
		this._onPlaying = this._onPlaying.bind(this);
		Component.apply(this, arguments);
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.initMediaElement();
	},

	initMediaElement() {
		const mediaEl = this.el && this.query("audio,video");
		if (mediaEl) {
			const MediaElementPlayer = global.MediaElementPlayer;
			if (!MediaElementPlayer) {
				throw new Error("Missing 'MediaElementPlayer' class in global scope.");
			}
			this.player = new MediaElementPlayer(mediaEl, {
				pluginPath: "assets/",
				stretching: "responsive",
				success: this._onMediaElementSuccess
			});
		}
		return this;
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.initMediaElement();
		return this;
	},

	pause() {
		if (this.player) {
			this.player.pause();
		}
		return this;
	},

	play() {
		if (this.player) {
			this.player.play();
		}
		return this;
	},

	remove() {
		if (this.player) {
			if (!this.player.paused) {
				this.player.pause();
			}
			if (this.unsubscribePlayer) {
				this.unsubscribePlayer();
				this.unsubscribePlayer = undefined;
			}
			this.player.remove();
		}
		this.player = undefined;
		return Component.prototype.remove.apply(this, arguments);
	},

	render() {
		Component.prototype.render.apply(this, arguments);
		this.initMediaElement();
		return this;
	},

	_onMediaElementSuccess(media) {
		media.addEventListener("ended", this._onEnded);
		media.addEventListener("pause", this._onPause);
		media.addEventListener("playing", this._onPlaying);

		let subscribed = true;
		this.unsubscribePlayer = () => {
			if (subscribed) {
				media.removeEventListener("ended", this._onEnded);
				media.removeEventListener("pause", this._onPause);
				media.removeEventListener("playing", this._onPlaying);
				subscribed = false;
			}
		};
	},

	_onEnded() {
		this.isPlaying = false;
		this.isEnded = true;
	},

	_onPause() {
		this.isPlaying = false;
	},

	_onPlaying() {
		this.isPlaying = true;
	}

});
