import Component from "../lib/components/component";
import dom from "ampersand-dom";

const toggleClass = (el, className, state) => {
	state = state === undefined ? !dom.hasClass(className) : state;
	dom[state ? "addClass" : "removeClass"](el, className);
	return el;
};

export default Component.extend({

	props: {
		activeSection: "string"
	},

	events: {
		"click a[data-action]": "_onActionClick"
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.listenTo(this, "change:activeSection", this._handleActiveSectionChange);
	},

	toggleActive(name, state) {
		var element = this.query("[data-section=\"" + name + "\"]");
		if (element) {
			toggleClass(element, "isActive", state);
		}
		return this;
	},

	_handleActiveSectionChange(target, newVal) {
		var prevVal = this.previous("activeSection");
		if (prevVal) {
			this.toggleActive(prevVal, false);
		}
		if (newVal) {
			this.toggleActive(newVal, true);
		}
	},

	_onActionClick(e) {
		let target = e.delegateTarget || e.currentTarget;
		let action = target.getAttribute("data-action");
		if (action) {
			this.send.apply(this, action.split(":"));
		}
		e.preventDefault();
	}

});
