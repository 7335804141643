import State from "ampersand-state";

/**
@class RotationData
@extends State
**/
export default State.extend({

	props: {
		/**
		The direction the device is facing according to the compass. Meaning
		the motion of the device around the z axis, represented in degrees with
		values ranging from 0 to 360.

		@property alpha
		@type number
		@default 0
		**/
		alpha: {
			type: "number",
			required: true,
			default: 0
		},

		/**
		The motion of the device around the x axis, represented in degrees with
		values ranging from -180 to 180.  This represents a front to back motion
		of the device.

		@property beta
		@type number
		@default 0
		**/
		beta: {
			type: "number",
			required: true,
			default: 0
		},

		/**
		The motion of the device around the y axis, represented in degrees with
		values ranging from -90 to 90. This represents a left to right motion of
		the device.

		@property gamma
		@type number
		@default 0
		**/
		gamma: {
			type: "number",
			required: true,
			default: 0
		}
	}

});
