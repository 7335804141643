import MotionComponent from "./motion";
import {createResponsiveBackgroundSet} from "../lib/image";

export default MotionComponent.extend({

	initialize() {
		MotionComponent.prototype.initialize.apply(this, arguments);
		this.on("change:rect.width", this.updateBackgroundImagesSize);
		this.initBackgroundImages();
	},

	initBackgroundImages() {
		const elements = this.el ? this.queryAll("[data-srcset]") : null;
		if (elements && elements.length) {
			this.images = createResponsiveBackgroundSet(elements);
			this.images.resize(this.rect.width, this.rect.height);
		}
		return this;
	},

	mount() {
		MotionComponent.prototype.mount.apply(this, arguments);
		this.initBackgroundImages();
		return this;
	},

	remove() {
		this.images = undefined;
		return MotionComponent.prototype.remove.apply(this, arguments);
	},

	render() {
		MotionComponent.prototype.render.apply(this, arguments);
		this.initBackgroundImages();
		return this;
	},

	updateBackgroundImagesSize() {
		if (this.images) {
			this.images.update(this.rect.width, this.rect.height);
		}
	}

});
