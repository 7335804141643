import Component from "../lib/components/component";
import MenuComponent from "../components/menu";
import MotionManager from "../services/motion-manager";
import Region from "../lib/components/region";
import componentScrollTo from "scroll-to";

export default Component.extend({

	props: {
		activePath: "string",
		activeSection: "string",
		isLoading: "boolean",
		pageTitle: "string",
		theme: "string"
	},

	children: {
		motionManager: MotionManager
	},

	bindings: {
		isLoading: {
			type: "booleanClass"
		},
		theme: {
			type: "class"
		}
	},

	events: {
		"click a[href]": "_onLinkClick"
	},

	components: {
		menu: {
			type: MenuComponent,
			hook: "menu-view",
			bindings: {
				activeSection: "activeSection"
			}
		},
		main: {
			type: Region,
			hook: "main-region"
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.on("change:pageTitle", this._handlePageTitleChange);
		this.listenTo(this.main, "hide", this._handleMainRegionHide);
		this.listenTo(this.main, "show", this._handleMainRegionShow);
	},

	// -- Public Methods -------------------------------------------------------

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.motionManager.startViewportUpdates();
		return this;
	},

	remove() {
		Component.prototype.remove.apply(this, arguments);
		this.motionManager.remove();
		return this;
	},

	render() {
		this.mountComponents();
		return this;
	},

	scrollTo(x = 0, y = 0) {
		let newX = x;
		let newY = y;

		if (newX > 0) {
			const maxX = this.el.offsetWidth - this.motionManager.viewport.width;
			newX = newX < maxX ? newX : maxX;
		}

		if (newY > 0) {
			const maxY = this.el.offsetHeight - this.motionManager.viewport.height;
			newY = newY < maxY ? newY : maxY;
		}

		componentScrollTo(newX, newY, {
			duration: 400
		});
		return this;
	},

	_handleMainRegionHide(region, component) {
		component.motionManager = undefined;
	},

	_handleMainRegionShow(region, component) {
		component.motionManager = this.motionManager;
	},

	_handlePageTitleChange(target, newVal) {
		if (this.rendered) {
			document.title = newVal;
		}
	},

	_onLinkClick(e) {
		const target = e.delegateTarget || e.currentTarget;
		const local = (target.host === window.location.host);
		const hasTarget = target.target && target.target === "_blank";
		// if it's a plain click (no modifier keys)
		// and it's a local url, navigate internally
		if (local && !hasTarget && !e.ctrlKey && !e.shiftKey && !e.altKey &&
				!e.metaKey && !e.defaultPrevented) {
			e.preventDefault();
			this.send("navigate", target.pathname + target.search);
		}
	}

});
