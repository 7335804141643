import Collection from "ampersand-collection";
import State from "ampersand-state";

export const ImageAsset = State.extend({

	props: {
		id: "string",
		title: "string",
		brightness: "string",
		credit: "string",
		ratio: "number",
		srcset: "string",
		tone: "string"
	}

});

export const ImageAssetCollection = Collection.extend({

	model: ImageAsset

});
