import Component from "../lib/components/component";
import AudioPlayer from "../components/audio-player";
import Footer from "../components/footer";
import Gallery from "../components/gallery";
import Poster from "../components/poster";
import ResponsiveVideo from "../components/responsive-video";
import VideoPlayer from "../components/video-player";
import assign from "lodash/assign";
import offset from "document-offset";
import {picturefillComponent} from "../lib/utils";

const components = {
	"audio-player": {
		type: AudioPlayer
	},
	"poster": {
		type: Poster,
		bindings: {
			motionManager: "motionManager"
		}
	},
	"responsive-video": {
		type: ResponsiveVideo
	},
	"slideshow": {
		type: Gallery
	},
	"video-player": {
		type: VideoPlayer
	}
};

const parsePropsFromEl = el => {
	const container = el && el.querySelector("script[type=\"application/json\"]");
	if (container) {
		return JSON.parse(container.textContent);
	}
};

export default Component.extend({

	props: {
		model: "state",
		motionManager: "state",
		playing: "state"
	},

	components: {
		footer: {
			type: Footer,
			hook: "footer-view"
		}
	},

	actions: {

		changePlaying(component, isPlaying) {
			if (isPlaying) {
				if (this.playing && this.playing !== component) {
					this.playing.pause();
				}
				this.playing = component;
			} else if (this.playing === component) {
				this.unset("playing");
			}
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.createPageComponents();
	},

	createPageComponents() {
		if (this.el) {
			const elements = this.el.querySelectorAll("[data-component]");
			Array.prototype.forEach.call(elements, element => {
				const type = element.getAttribute("data-component");
				const config = type && components[type];
				if (config) {
					let props;
					try {
						props = parsePropsFromEl(element);
					} catch (e) {
						this.send("error", e);
					}
					this.createComponent(assign({el: element}, config, {props}));
				}
			});
		}
		return this;
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.createPageComponents();
		picturefillComponent(this);
		return this;
	},

	render() {
		this.renderWithContext(this);
		this.mountComponents();
		this.createPageComponents();
		picturefillComponent(this);
		return this;
	},

	scrollToContact() {
		if (this.footer && this.footer.el) {
			const footerOffset = offset(this.footer.el);
			this.send("scrollTo", footerOffset.top);
		}
	},

	template() {
		return this.model && this.model.body;
	}

});
