import State from "ampersand-state";
import AccelerationData from "./acceleration-data";
import RotationData from "./rotation-data";

/**
@class MotionData
@extends State
**/
export default State.extend({

	children: {
		/**
		@property acceleration
		@type AccelerationData
		**/
		acceleration: AccelerationData,

		/**
		@property rotationRate
		@type RotationData
		**/
		rotationRate: RotationData
	}

});
