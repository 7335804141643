import Component from "../lib/components/component";
import Rect from "../services/motion-manager/rect";
import offsetRect from "../lib/dom/offset-rect";

const calcVisibility = (rect, viewport) => {
	const context = rect.height < viewport.height ? rect.height : viewport.height;
	if (viewport.y > rect.y) {
		return 1 - (viewport.y - rect.y) / context;
	}
	if ((viewport.y + viewport.height) < (rect.y + rect.height)) {
		return ((viewport.y + viewport.height) - rect.y) / context;
	}
	return 1;
};

export const ComponentRect = Rect.extend({

	props: {
		/**
		@property isFullyVisible
		@type boolean
		@default false
		**/
		isFullyVisible: {
			type: "boolean",
			required: true,
			default: false
		},

		/**
		@property isVisible
		@type boolean
		@default false
		**/
		isVisible: {
			type: "boolean",
			required: true,
			default: false
		},

		/**
		@property scrollY
		@type boolean
		@default null
		**/
		scrollY: {
			type: "number",
			required: true,
			allowNull: true,
			default: null
		},

		/**
		@property visibility
		@type number
		@default 0
		**/
		visibility: {
			type: "number",
			required: true,
			default: 0
		}
	}

});

/**
@class MotionComponent
@extends Component
**/
export default Component.extend({

	// -- Public Properties ----------------------------------------------------

	props: {
		/**
		@property motionManager
		@type null|MotionManager
		@default null
		**/
		motionManager: "state"
	},

	children: {
		/**
		@property rect
		@type Rect
		**/
		rect: ComponentRect
	},

	// -- Lifecycle Methods ----------------------------------------------------

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		if (this.motionManager) {
			this._attachMotionManager(this.motionManager);
		}
		this.on("change:motionManager", this._handleMotionManagerChange);
	},

	// -- Public Methods -------------------------------------------------------

	/**
	@method remove
	@chainable
	**/
	remove() {
		if (this.motionManager) {
			this._detachMotionManager(this.motionManager);
		}
		return Component.prototype.remove.apply(this, arguments);
	},

	/**
	@method updateRect
	@param {Object} viewport
		@param {number} viewport.height
		@param {number} viewport.width
		@param {number} viewport.x
		@param {number} viewport.y
	@chainable
	**/
	updateRect(viewport) {
		if (this.el) {
			const viewRect = offsetRect(this.el);
			viewRect.isVisible = Rect.hasIntersection(viewRect, viewport);
			viewRect.scrollY = viewRect.isVisible ? viewport.y - viewRect.y : null;
			viewRect.visibility = viewRect.isVisible ? calcVisibility(viewRect, viewport) : 0;
			viewRect.isFullyVisible = viewRect.visibility === 1;
			this.rect.set(viewRect);
		}
		return this;
	},

	// -- Protected Methods ----------------------------------------------------

	/**
	@private
	@method _attachMotionManager
	@param {MotionManager} motionManager
	**/
	_attachMotionManager(motionManager) {
		this.listenTo(motionManager, "update:viewport", this._handleMotionManagerViewportUpdate);
		if (typeof this.attachMotionManager === "function") {
			this.attachMotionManager(motionManager);
		}
		this.updateRect(motionManager.viewport);
	},

	/**
	@private
	@method _detachMotionManager
	@param {MotionManager} motionManager
	**/
	_detachMotionManager(motionManager) {
		if (typeof this.detachMotionManager === "function") {
			this.detachMotionManager(motionManager);
		}
		this.stopListening(motionManager);
	},

	// -- Protected Event Handlers ---------------------------------------------

	/**
	@private
	@method _handleMotionManagerChange
	@param {Component} target
	@param {MotionManager|null} motionManager
	**/
	_handleMotionManagerChange(target, newVal) {
		const prevVal = target.previous("motionManager");
		if (prevVal) {
			this._detachMotionManager(prevVal);
		}
		if (newVal) {
			this._attachMotionManager(newVal);
		}
	},

	/**
	@private
	@method _handleMotionManagerChange
	@param {Component} target
	@param {MotionManager|null} motionManager
	**/
	_handleMotionManagerViewportUpdate(target, newVal) {
		this.updateRect(newVal);
	}

});
