import State from "ampersand-state";

const hasIntersection = (a, b) => {
	if ((!a || typeof a !== "object") || (!b || typeof b !== "object")) {
		return false;
	}
	const aTop = a.y || 0;
	const aLeft = a.x || 0;
	const aRight = aLeft + (a.width || 0);
	const aBottom = aTop + (a.height || 0);
	const bTop = b.y || 0;
	const bLeft = b.x || 0;
	const bRight = bLeft + (b.width || 0);
	const bBottom = bTop + (b.height || 0);
	// A is considered to intersect with B when:
	// 1. B top is above A bottom
	// 2. B bottom is below A top
	// 3. B left is left of A right
	// 4. B right is right of A left
	return (bTop <= aBottom &&
			bBottom >= aTop &&
			bLeft <= aRight &&
			bRight >= aLeft);
};

/**
@class Rect
@extends State
**/
const Rect = State.extend({

	props: {
		/**
		@property height
		@type number
		**/
		height: {
			type: "number",
			required: true,
			default: 0
		},

		/**
		@property width
		@type number
		**/
		width: {
			type: "number",
			required: true,
			default: 0
		},

		/**
		@property x
		@type number
		**/
		x: {
			type: "number",
			required: true,
			default: 0
		},

		/**
		@property y
		@type number
		**/
		y: {
			type: "number",
			required: true,
			default: 0
		}
	},

	/**
	@method contains
	@param {Object|Rect} rect
	@returns {boolean}
	**/
	contains(rect) {
		return hasIntersection(this, rect);
	}

});

Rect.hasIntersection = hasIntersection;

export default Rect;
