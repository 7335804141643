var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "		<div class=\"Figure Figure--"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.brightness : stack1), depth0))
    + " Figure--"
    + alias2(((helper = (helper = helpers.style || (depth0 != null ? depth0.style : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"style","hash":{},"data":data}) : helper)))
    + "\""
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.tone : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n			<div class=\"Figure-visual\">\n				<div class=\"ResponsiveMedia ResponsiveMedia--"
    + alias2(((helper = (helper = helpers.alignment || (depth0 != null ? depth0.alignment : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"alignment","hash":{},"data":data}) : helper)))
    + " ResponsiveMedia--"
    + alias2(((helper = (helper = helpers.style || (depth0 != null ? depth0.style : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"style","hash":{},"data":data}) : helper)))
    + " ResponsiveMedia--"
    + alias2(((helper = (helper = helpers.scale || (depth0 != null ? depth0.scale : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"scale","hash":{},"data":data}) : helper)))
    + "\""
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.ratio : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n					<img class=\"ResponsiveMedia-body"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.orientation : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" sizes=\""
    + alias2(((helper = (helper = helpers.sizes || (depth0 != null ? depth0.sizes : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"sizes","hash":{},"data":data}) : helper)))
    + "\" srcset=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.srcset : stack1), depth0))
    + "\" alt=\"\">\n				</div>\n			</div>\n		</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " style=\"background-color: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.tone : stack1), depth0))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return " style=\"padding-bottom: "
    + container.escapeExpression(((helper = (helper = helpers.ratio || (depth0 != null ? depth0.ratio : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ratio","hash":{},"data":data}) : helper)))
    + "%\"";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return " ResponsiveMedia-body--"
    + container.escapeExpression(((helper = (helper = helpers.orientation || (depth0 != null ? depth0.orientation : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"orientation","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"Slide\" data-action=\"nextSlide\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.model : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});