import Collection from "../lib/data/collection";
import Model from "ampersand-model";
import domify from "domify";
import restMixin from "ampersand-collection-rest-mixin";

const parseId = dom => {
	const main = dom.querySelector("[data-page]");
	return main && main.getAttribute("data-page") || "";
};

const parseBody = dom => {
	const main = dom.querySelector("[data-page]");
	return main && main.parentNode && main.parentNode.innerHTML || "";
};

const parsePageTitle = dom => {
	const title = dom.querySelector("title");
	return title && title.textContent || "";
};

const parseTheme = dom => {
	const el = dom.querySelector("[data-theme]");
	return el && el.getAttribute("data-theme") || "";
};

const parsePage = payload => {
	const dom = typeof payload === "string" ? domify(payload) : payload;
	return {
		type: "pages",
		id: parseId(dom),
		body: parseBody(dom),
		pageTitle: parsePageTitle(dom),
		theme: parseTheme(dom)
	};
};

export const Page = Model.extend({

	ajaxConfig: {
		headers: {
			"Accept": "text/html"
		}
	},

	typeAttribute: "type",

	props: {
		type: {
			type: "string",
			required: true,
			default: "pages"
		},
		id: "string",
		body: "string",
		pageTitle: "string",
		theme: "string"
	},

	parse(payload) {
		return parsePage(payload);
	},

	url() {
		return "/" + this.getId();
	}

});

export const PageCollection = Collection.extend(restMixin, {

	model: Page

});
