import Route from "../lib/routing/route";
import PageComponent from "../views/page";

export default Route.extend({

	props: {
		currentModel: "state"
	},

	actions: {

		scrollToContact() {
			if (this.main) {
				this.main.scrollToContact();
			}
		},

		updatePageTitle() {
			if (this.currentModel) {
				const root = this.routeFor("root");
				root.pageTitle = this.currentModel.pageTitle;
			}
		}
	},

	model({path}) {
		return this.store.find("pages", path);
	},

	setup(model) {
		this.currentModel = model;
		this.main = this.createComponent({
			type: PageComponent,
			props: {
				model
			}
		});
		this.renderComponent(this.main);
		this.send("activePath", model.id);
		this.send("changeTheme", model.theme);
	},

	reset() {
		this.removeComponent(this.main);
		this.main = undefined;
		this.unset("currentModel");
	}

});
