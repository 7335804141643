var ResolvedHandlerInfo = require("./resolved-handler-info");
var UnresolvedHandlerInfoByObject = require("./unresolved-handler-info-by-object");
var UnresolvedHandlerInfoByParam = require("./unresolved-handler-info-by-param");

function handlerInfoFactory(name, props) {
	var Factory = handlerInfoFactory.handlerInfos[name];
	var handlerInfo = new Factory(props || {});
	handlerInfo.factory = handlerInfoFactory;
	return handlerInfo;
}

handlerInfoFactory.handlerInfos = {
	resolved: ResolvedHandlerInfo,
	param: UnresolvedHandlerInfoByParam,
	object: UnresolvedHandlerInfoByObject
};

module.exports = handlerInfoFactory;
