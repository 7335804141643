import Router from "./router";
import Store from "./store";

function App(data) {
	this.store = new Store(data, {parse: true});
	this.router = new Router({
		store: this.store
	});
}

App.prototype.start = function (routes = {}) {
	const keys = Object.keys(routes);
	for (let i = keys.length - 1; i >= 0; i--) {
		this.router.route(keys[i], routes[keys[i]]);
	}
	this.router.start({
		isFastBoot: true,
		pushState: true
	});
};

export default App;
