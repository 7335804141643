import State from "ampersand-state";
import MotionData from "./motion-data";
import RotationData from "./rotation-data";
import ViewportData from "./viewport-data";
import cloneDeep from "lodash/cloneDeep";
import clone from "lodash/clone";
import events from "events-mixin";
import {onAnimationFrame} from "../../lib/animation/animation-frame";

const scrollTop = el => {
	if (el && el !== window && el !== document && el !== document.body) {
		return el.scrollTop;
	}
	return window.scrollY || window.pageYOffset || document.body.scrollTop ||
			document.documentElement.scrollTop;
};

export default State.extend({

	// -- Public Properties ----------------------------------------------------

	props: {
		/**
		@property isMotionActive
		@type boolean
		@default false
		**/
		isMotionActive: {
			type: "boolean",
			required: true,
			default: false
		},

		/**
		@property isMotionAvailable
		@type boolean
		**/
		isMotionAvailable: {
			type: "boolean",
			setOnce: true
		},

		/**
		@property isOrientationActive
		@type boolean
		@default false
		**/
		isOrientationActive: {
			type: "boolean",
			required: true,
			default: false
		},

		/**
		@property isOrientationAvailable
		@type boolean
		**/
		isOrientationAvailable: {
			type: "boolean",
			setOnce: true
		},

		/**
		@property isViewportActive
		@type boolean
		@default false
		**/
		isViewportActive: {
			type: "boolean",
			required: true,
			default: false
		}
	},

	children: {
		/**
		@property motion
		@type MotionData
		**/
		motion: MotionData,

		/**
		@property orientation
		@type RotationData
		**/
		orientation: RotationData,

		/**
		@property viewport
		@type ViewportData
		**/
		viewport: ViewportData
	},

	// -- Lifecycle Methods ----------------------------------------------------

	initialize() {
		this._afterResize = this._afterResize.bind(this);
		this._tick = this._tick.bind(this);
		this.set({
			isMotionAvailable: !!(window.DeviceMotionEvent),
			isOrientationAvailable: !!(window.DeviceOrientationEvent)
		});
		this._initEventManager();
	},

	// -- Public Methods -------------------------------------------------------

	remove() {
		if (this.eventManager) {
			this.eventManager.unbind();
		}
		this.isMotionActive = false;
		this.isOrientationActive = false;
		this.isViewportActive = false;
		return this;
	},

	startMotionUpdates() {
		if (this.isMotionAvailable && !this.isMotionActive) {
			this.eventManager.bind("devicemotion", "_onDeviceMotion");
			this.isMotionActive = true;
		}
		return this;
	},

	startOrientationUpdates() {
		if (this.isOrientationAvailable && !this.isOrientationActive) {
			this.eventManager.bind("deviceorientation", "_onDeviceOrientation");
			this.isOrientationActive = true;
		}
		return this;
	},

	startViewportUpdates() {
		if (!this.isViewportActive) {
			this.eventManager.bind("scroll", "_onScroll");
			this.eventManager.bind("resize", "_onResize");
			this.eventManager.bind("orientationchange", "_onOrientationChange");
			this.isViewportActive = true;
			// Init viewport data because it is altered by multiple events which
			// might lead to missing data if only one event triggers.
			this._updateViewport();
			this.viewport.set(this._viewport);
			this._viewportIsUpdated = false;
		}
		return this;
	},

	stopMotionUpdates() {
		if (this.isMotionActive) {
			this.eventManager.unbind("devicemotion");
			this.isMotionActive = false;
		}
		return this;
	},

	stopOrientationUpdates() {
		if (this.isOrientationActive) {
			this.eventManager.unbind("deviceorientation");
			this.isOrientationActive = false;
		}
		return this;
	},

	stopViewportUpdates() {
		if (this.isViewportActive) {
			this.eventManager.unbind("scroll");
			this.eventManager.unbind("resize");
			this.eventManager.unbind("orientationchange");
			this.isViewportActive = false;
		}
		return this;
	},

	// -- Protected Methods ----------------------------------------------------

	_updateMotion(data) {
		data = data || {};
		if (!this._motion) {
			this._motion = {};
		}
		if (!this._motion.acceleration) {
			this._motion.acceleration = {};
		}
		if (!this._motion.rotationRate) {
			this._motion.rotationRate = {};
		}
		this._motion.acceleration.x = Math.round(data.acceleration.x || 0);
		this._motion.acceleration.y = Math.round(data.acceleration.y || 0);
		this._motion.acceleration.z = Math.round(data.acceleration.z || 0);
		this._motion.rotationRate.alpha = Math.round(data.rotationRate.alpha || 0);
		this._motion.rotationRate.beta = Math.round(data.rotationRate.beta || 0);
		this._motion.rotationRate.gamma = Math.round(data.rotationRate.gamma || 0);
		this._motionIsUpdated = true;
	},

	_updateOrientation(data) {
		data = data || {};
		if (!this._orientation) {
			this._orientation = {};
		}
		this._orientation.alpha = Math.round(data.alpha || 0);
		this._orientation.beta = Math.round(data.beta || 0);
		this._orientation.gamma = Math.round(data.gamma || 0);
		this._orientationIsUpdated = true;
	},

	_updateViewport() {
		this._updateViewportDimensions();
		this._updateViewportOrientation();
		this._updateViewportPosition();
	},

	_updateViewportPosition() {
		if (!this._viewport) {
			this._viewport = {};
		}
		this._viewport.x = 0;
		this._viewport.y = scrollTop();
		this._viewportIsUpdated = true;
	},

	_updateViewportDimensions() {
		if (!this._viewport) {
			this._viewport = {};
		}
		this._viewport.height = window.innerHeight;
		this._viewport.width = window.innerWidth;
		this._viewportIsUpdated = true;
	},

	_updateViewportOrientation() {
		if (!this._viewport) {
			this._viewport = {};
		}
		this._viewport.orientation = window.orientation || 0;
		this._viewportIsUpdated = true;
	},

	_initEventManager() {
		if (this.eventManager) {
			this.eventManager.unbind();
		}
		this.eventManager = events(window, this);
	},

	_tick(time) {
		var payload = {},
			props = {};

		if (this.isViewportActive && this._viewportIsUpdated) {
			payload.viewport = clone(this._viewport);
			this.trigger("update:viewport", this, clone(this._viewport));
			props.viewport = this._viewport;
		}

		if (this.isOrientationActive && this._orientationIsUpdated) {
			payload.orientation = clone(this._orientation);
			this.trigger("update:orientation", this, clone(this._orientation));
			props.orientation = this._orientation;
		}

		if (this.isMotionActive && this._motionIsUpdated) {
			payload.motion = cloneDeep(this._motion);
			this.trigger("update:motion", this, cloneDeep(this._motion));
			props.motion = this._motion;
		}

		payload.timestamp = time;
		this.trigger("update", this, payload);

		this.set(props);

		this._motionIsUpdated = false;
		this._orientationIsUpdated = false;
		this._viewportIsUpdated = false;
	},

	// -- Protected Event Handlers ---------------------------------------------

	_afterResize() {
		this._updateViewportDimensions();
		onAnimationFrame(this._tick);
	},

	_onDeviceMotion(e) {
		this._updateMotion(e);
		onAnimationFrame(this._tick);
	},

	_onDeviceOrientation(e) {
		this._updateOrientation(e);
		onAnimationFrame(this._tick);
	},

	_onOrientationChange() {
		this._updateViewportOrientation();
		onAnimationFrame(this._tick);
	},

	_onResize() {
		clearTimeout(this._resizeTimer);
		this._resizeTimer = setTimeout(this._afterResize, 300);
	},

	_onScroll() {
		this._updateViewportPosition();
		onAnimationFrame(this._tick);
	}

});
