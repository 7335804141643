import Router from "./lib/routing/router";
import RootRoute from "./routes/root";
import PageRoute from "./routes/page";

export default Router.extend({

	// -- Routes ---------------------------------------------------------------

	routes: {
		""      : "home",
		"*path" : "page"
	},

	// -- Public Properties ----------------------------------------------------

	handlers: {
		root : RootRoute,
		page : PageRoute
	},

	rootSelector: "[data-hook~=\"app\"]",

	// -- Lifecycle Methods ----------------------------------------------------

	initialize(config = {}) {
		this.store = config.store;
	},

	// -- Route Handlers -------------------------------------------------------

	root() {
		return this.setup("root");
	},

	page(path) {
		return this.root().setup("page", {path});
	},

	home() {
		return this.page("index");
	}

});
