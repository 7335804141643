import "./styles/webfonts.scss";
import "./styles/app.scss";
import PromisePolyfill from "promise-polyfill";
import App from "./app";
import domReady from "domready";
//import fastclick from "fastclick";

window.Promise = window.Promise || PromisePolyfill;

domReady(() => {
	//fastclick(document.body);
	const app = window.app = new App(window.__INITIAL_STATE__);
	app.start(window.__ROUTES__);
});
