import Store from "./lib/data/store";
import {PageCollection} from "./models/page";

export default Store.extend({

	collections: {
		pages: PageCollection
	}

});
