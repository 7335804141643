import Component from "../lib/components/component";
import MediaElement from "./media-element";

export default Component.extend({

	components: {
		audio: {
			type: MediaElement,
			hook: "audio"
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.listenTo(this.audio, "change:isPlaying", this._handleIsPlayingChange);
	},

	pause() {
		this.audio.pause();
		return this;
	},

	play() {
		this.audio.play();
		return this;
	},

	togglePlaying(state) {
		state = state === undefined || state === null ? !this.audio.isPlaying : state;
		this[state ? "play" : "pause"]();
		return this;
	},

	_handleIsPlayingChange(target, isPlaying) {
		this.send("changePlaying", this, isPlaying);
	}

});
