import Route from "../lib/routing/route";
import RootComponent from "../views/root";

export default Route.extend({

	props: {
		activePath: {
			type: "string"
		},
		activeSection: {
			type: "string"
		},
		isLoading: {
			type: "boolean",
			required: true,
			default: false
		},
		pageTitle: {
			type: "string"
		},
		theme: {
			type: "string"
		}
	},

	actions: {

		activePath(path) {
			this.activePath = path.replace(/^\/|\/$/g, "");
			this.activeSection = this.activePath.split("/").shift();
		},

		changeTheme(theme) {
			this.theme = theme;
		},

		didTransition() {
			const path = this.parent.history.fragment;
			this.send("updatePageTitle");
			this.send("trackPageview", {
				page: (path.charAt(0) !== "/" ? "/" : "") + path,
				title: this.pageTitle
			});
		},

		error(err) {
			if (window.console && typeof window.console.error === "function") {
				window.console.error(err);
			}
		},

		loading(promise) {
			this.isLoading = true;
			promise.then(res => {
				this.isLoading = false;
				return res;
			}, err => {
				this.isLoading = false;
				throw err;
			});
		},

		navigate(url, options) {
			if (this.parent.hasRoute(url)) {
				this.parent.navigate(url, options).then(() => {
					//this.send("scrollTo", 0);
					window.scroll(0, 0);
				}, (err) => {
					this.send("error", err);
				});
			} else {
				this.parent.history.location.assign(url);
			}
		},

		scrollTo(newY, options) {
			if (this.main) {
				this.main.scrollTo(0, newY, options);
			}
		},

		trackEvent({category, action, label, value}) {
			const ga = window.ga;
			if (ga) {
				ga("send", "event", category, action, label, value);
			}
		},

		trackPageview({page}) {
			const ga = window.ga;
			if (ga && page) {
				const path = page.replace(new RegExp("^" + document.location.origin), "");
				ga("set", "page", (path.charAt(0) !== "/" ? "/" : "") + path);
				ga("send", "pageview");
			}
		}
	},

	model() {
		this.store.add("pages", document);
		return undefined;
	},

	enter() {
		this.main = this.createComponent({
			type: RootComponent,
			bindings: {
				activePath: "activePath",
				activeSection: "activeSection",
				isLoading: "isLoading",
				pageTitle: "pageTitle",
				theme: "theme"
			}
		});
	},

	setup() {
		this.renderComponent(this.main);
	},

	exit() {
		this.removeComponent(this.main);
		this.main = undefined;
	}

});
